<template>
  <v-radio-group
    v-if="radioContent"
    v-model="selectedOption"
    class="ma-0 pa-0"
    hide-details
  >
    <v-row v-if="slideDisplay">
      <v-slide-group
        multiple
        show-arrows
      >
        <v-slide-item
          v-for="item in radioContent"
          :key="item.value"
          v-slot="{ active, toggle }"
          class="align-center"
        >
          <div
            class="px-2"
            @click="toggle"
          >
            <label
              class="label-custom-input custom-input custom-radio rounded cursor-pointer"
              :class="selectedOption === item.value ? 'active' : ''"
              :input-value="active"
            >
              <div>
                <v-radio
                  :value="item.value"
                  color="secondary"
                />
              </div>
              <slot :item="item">
                <div class="flex-grow-1">
                  <div class="d-flex align-center mb-1">
                    <h6 class="cr-title text-base">
                      {{ item.title }}
                    </h6>
                    <v-spacer />
                    <span
                      v-if="item.desc"
                      class="text-sm text-disabled"
                    >{{ item.desc }}</span>
                  </div>
                  <p class="text-sm mb-0">
                    {{ item.subtitle }}
                  </p>
                </div>
              </slot>
            </label>
          </div>
        </v-slide-item>
      </v-slide-group>
    </v-row>

    <v-row v-if="grigDisplay && !slideDisplay">
      <v-col
        v-for="item in radioContent"
        :key="item.value"
        v-bind="gridColumn"
      >
        <label
          class="label-custom-input custom-input custom-radio rounded cursor-pointer"
          :class="selectedOption === item.value ? 'active' : ''"
        >
          <div>
            <v-radio
              :value="item.value"
              color="secondary"
            />
          </div>
          <slot :item="item">
            <div class="flex-grow-1">
              <div class="d-flex align-center mb-1">
                <h6 class="cr-title text-base">
                  {{ item.title }}
                </h6>
                <v-spacer />
                <span
                  v-if="item.desc"
                  class="text-sm text-disabled"
                >{{ item.desc }}</span>
              </div>
              <p class="text-sm mb-0">
                {{ item.subtitle }}
              </p>
            </div>
          </slot>
        </label>
      </v-col>
    </v-row>
  </v-radio-group>
</template>

<script>
import { computed, watch } from '@vue/composition-api'

export default {
  model: {
    prop: 'selectedRadio',
    event: 'update:selectedRadio',
  },
  props: {
    selectedRadio: {
      type: [String, Number],
      required: true,
    },
    radioContent: {
      type: Array,
      required: true,
    },
    grigDisplay: {
      type: Boolean,
      required: false,
      default: true,
    },
    gridColumn: {
      type: null,
      required: false,
      default: () => ({}),
    },
    slideDisplay: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    // const selectedOption = ref(structuredClone(toRaw(props.selectedRadio)))
    const selectedOption = computed({
      get: () => props.selectedRadio,
      set: val => {
        emit('update:selectedRadio', val)
      },
    })

    watch(selectedOption, () => {
      emit('update:selectedRadio', selectedOption.value)
    })

    return {
      selectedOption,
    }
  },
}

// const emit = defineEmits(['update:selectedRadio'])
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';
.custom-radio {
  display: flex;
  align-items: center;
  gap: 0.375rem;
  padding: 0.5rem 0.8rem;
  border: 1px solid rgba(0, 0, 0, 0.14);

  .v-radio {
    margin-block-start: -0.45rem;
  }

  .cr-title {
    font-weight: 500;
  }
}

// .label-custom-input {
//   background-color: #43cd12;
//   padding: 1rem;
//   border: 1px solid rgba(var(--v-border-color), 0.12);
//   opacity: 1;
//   white-space: normal;

//   &:hover {
//     border-color: rgba(var(--v-border-color), 0.25);
//   }

//   &.active {
//     border-color: rgb(var(--v-theme-primary));

//     .v-icon {
//       color: rgb(var(--v-theme-primary)) !important;
//     }
//   }
// }
</style>
