var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-full"},[_c('div',{staticClass:"drawer-header d-flex align-center justify-space-between mb-0"},[_c('div',{staticClass:"d-flex flex-column flex-sm-row justify-sm-space-between align-start align-sm-center w-100 "},[_c('div',{staticClass:"d-flex align-center mb-2 mb-sm-0"},[_c('span',{staticClass:"font-weight-semibold text-base text--primary"},[_vm._v(_vm._s(_vm.t('Booking'))+" #"+_vm._s(_vm.dataParams.reservation_id))]),_c('v-chip',{staticClass:"ml-2",attrs:{"small":"","color":"warning"}},[_vm._v(" "+_vm._s(_vm.dataParams.status_str)+" ")])],1),_c('div',[(_vm.dataParams.recurring_id)?_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","small":_vm.$vuetify.breakpoint.smAndDown},on:{"click":function($event){return _vm.$emit('recurring')}}},[_c('v-icon',{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAutorenew)+" ")]),_vm._v(" Recurring #"+_vm._s(_vm.dataParams.recurring_id)+" ")],1):_vm._e(),_c('v-btn',{staticClass:"mr-2 btn-share",attrs:{"outlined":"","small":_vm.$vuetify.breakpoint.smAndDown},on:{"click":function () {
            _vm.$emit('share', _vm.dataParams)
            _vm.$emit('openClose', false)
          }}},[_c('v-icon',{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiShareVariant)+" ")])],1)],1)]),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$emit('openClose', false)}}},[_c('v-icon',{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1)],1),_c('div',{staticClass:"ma-4",attrs:{"id":"video-view"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-tabs',{staticStyle:{"box-shadow":"none !important"},attrs:{"color":"secondary","show-arrows":true},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},_vm._l((_vm.computedTabs),function(tab){return _c('v-tab',{key:tab.title},[(tab.icon)?_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(tab.icon)+" ")]):_vm._e(),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(tab.title)))])],1)}),1),_c('v-tabs-items',{staticClass:"mt-5 overflow-visible",attrs:{"id":"video-tabs-content"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.computedTabs),function(tab){return _c('v-tab-item',{key:tab.component},[_c(tab.component,_vm._b({tag:"component"},'component',tab.props,false))],1)}),1)],1)],1),_c('div',{staticClass:"d-flex mb-5"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"secondary","outlined":"","small":_vm.$vuetify.breakpoint.smAndDown},on:{"click":function () {
          _vm.onCancelReserve()
          _vm.$emit('openClose', false)
        }}},[_vm._v(" Cancel Booking ")]),_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","small":_vm.$vuetify.breakpoint.smAndDown},on:{"click":function () {
          _vm.$emit('share', _vm.dataParams)
          _vm.$emit('openClose', false)
        }}},[_vm._v(" Share ")]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","small":_vm.$vuetify.breakpoint.smAndDown},on:{"click":function($event){return _vm.$emit('openClose', false)}}},[_vm._v(" Close ")])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }