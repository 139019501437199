<template>
  <div>
    <v-card
      class="max-h-content-container app-calendar position-relative overflow-hidden text-sm"
    >
      <v-row
        class="px-0 ma-0 text-center"
        align="center"
      >
        <v-col
          v-if="false"
          cols="12"
          sm="3"
        >
          <v-select
            v-model="statusFilter"
            class="text-h6"
            :label="t('status.status')"
            :items="statusOptions"
            item-text="text"
            item-value="value"
            :menu-props="{ maxHeight: '400' }"
            multiple
            persistent-hint
            dense
            outlined
            hide-details
            :append-icon="icons.mdiMenuDown"
            @change="updateFilter('views-reservations-schedule', 'status', statusFilter)"
          >
            <template #selection="data">
              <v-chip
                v-bind="data.attrs"
                class="text-body-1"
                label
                dense
                :input-value="data.selected"
                @click="data.select"
              >
                {{ data.item.text }}
              </v-chip>
            </template>
          </v-select>
        </v-col>

        <v-col
          v-if="false"
          cols="12"
          sm="3"
        >
          <v-select
            v-model="typesFilter"
            class="text-h6"
            :label="t('notifications.type')"
            :items="typesOptions"
            item-text="text"
            item-value="value"
            :menu-props="{ maxHeight: '400' }"
            multiple
            persistent-hint
            dense
            outlined
            hide-details
            :append-icon="icons.mdiMenuDown"
            @change="updateFilter('views-reservations-schedule', 'type', typesFilter)"
          >
            <template #selection="data">
              <v-chip
                v-bind="data.attrs"
                label
                dense
                :input-value="data.selected"
                @click="data.select"
              >
                {{ data.item.text }}
              </v-chip>
            </template>
          </v-select>
        </v-col>

        <v-col
          v-if="false"
          cols="12"
          sm="2"
        >
          <v-btn
            class="text-h6"
            color="secondary"
            block
            @click="openCloseInsight(!isInsightOpen)"
          >
            {{ t('reservations.insights') }}
            <v-icon class="ml-2">
              {{ icons.mdiSearchWeb }}
            </v-icon>
          </v-btn>
        </v-col>

        <v-col
          cols="12"
          md="4"
          sm="4"
          class="p-sm-0"
        >
          <div class="d-flex justify-space-between align-center">
            <v-btn
              icon
              class="d-inline-block me-1"
              @click="isFilterDrawerActive = true"
            >
              <v-icon>
                {{ icons.mdiMenu }}
              </v-icon>
            </v-btn>
            <v-btn
              class="me-1 pa-0 btn-arrows"
              color="primary"
              outlined
              @click="onChangeDay(1)"
            >
              <v-icon size="30">
                {{ icons.mdiChevronLeft }}
              </v-icon>
            </v-btn>
            <v-menu
              v-model="modalCustomDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="customDateFilter"
                  :placeholder="t('videos.date')"
                  class="text-body-1"
                  :prepend-icon="icons.mdiCalendar"
                  outlined
                  readonly
                  dense
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateStr"
                color="secondary"
                @input="modalCustomDate = false"
                @change="onChangeDay(-1, dateStr)"
              ></v-date-picker>
              <!-- @change="updateFilter('views-order-video-list', 'date', dateFilter)" -->
            </v-menu>

            <v-btn
              class="ms-1 pa-0 btn-arrows"
              color="primary"
              outlined
              @click="onChangeDay(2)"
            >
              <v-icon size="30">
                {{ icons.mdiChevronRight }}
              </v-icon>
            </v-btn>
          </div>
        </v-col>

        <v-spacer />

        <v-col
          cols="12"
          sm="auto only-desktop"
        >
          <v-btn
            color="secondary"
            block
            @click="onNewReservation"
          >
            {{ t('reservations.new_reservation') }}
          </v-btn>
        </v-col>

        <v-col
          v-if="false"
          cols="12"
          sm="1"
        >
          <reservation-notification
            :notifications="calendarEventsNoReason"
            :notifications-count="calendarEventsNoReason.length"
            @refreshEvents="onRefreshEvents"
            @openDetail="onOpenEventDetail"
          />
        </v-col>

        <v-col
          v-if="false"
          cols="12"
          md="4"
          class="py-0"
        >
          <v-btn-toggle
            v-model="toggleWeek"
            mandatory
          >
            <v-btn
              class="text-h6"
              @click="onToggleWeek(0)"
            >
              {{ t('tooltip.this_week') }}
            </v-btn>

            <v-btn
              class="text-h6"
              @click="onToggleWeek(1)"
            >
              {{ t('tooltip.prev') }}
            </v-btn>

            <v-btn
              class="text-h6"
              @click="onToggleWeek(2)"
            >
              {{ t('tooltip.next') }}
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>

      <v-divider />

      <div
        v-if="configOrganization && configFacility"
        class="h-full"
        style="overflow-x: scroll;"
      >
        <v-card-text
          v-if="!facilityOpen"
          class="text-center text-body-1 py-14"
        >
          {{ t('reservations.no_active_fields') }}
        </v-card-text>

        <v-calendar
          v-if="!isInsightOpen && facilityOpen"
          ref="refCalendar"
          v-model="calendarValue"
          class="text-body-1"
          :type="activeCalendarView"
          :categories="categories"
          category-show-all
          :interval-height="smallWidth ? 28 : 35"
          :interval-width="smallWidth ? 75 : 90"
          :interval-minutes="15"
          :interval-count="intervalCount"
          :interval-format="intervalFormat"
          :events="calendarEvents"
          :event-margin-bottom="5"
          @click:event="calenderHandleEventClick"
          @click:time-category="calendarHandleDayCategotyClick"
        >
          <template v-slot:event="{ event }">
            <event :event="event">
              <template v-slot:share-icon>
                <share-copy
                  v-if="event.extended_props.card_type === 'reservation'"
                  :link="event.reservation_share_url"
                  :sport="event.sport_name"
                  :date="event.date_str"
                  :start-time="event.time_str_short"
                  :location="`${event.facility_name} • ${event.field_name}`"
                  :is-prevent.sync="isPrevent"
                  :show-share="false"
                  copy-icon-color="white"
                  @openUpdateShare="onUpdateShare(event)"
                />
              </template>
            </event>
          </template>
        </v-calendar>

        <insights-component
          v-if="isInsightOpen"
          :is-open.sync="isInsightOpen"
          :data-group="configOrganization"
          :data-facility="configFacility"
          :data-fecha-inicio="dateRangeStart"
          :data-fecha-fin="dateRangeEnd"
          @openClose="openCloseInsight"
        ></insights-component>
      </div>

      <v-card-text
        v-else
        class="text-center text-body-1"
      >
        {{ t('reservations.no_active_fields') }}
      </v-card-text>
    </v-card>

    <!-- <reservation-new
      :is-open.sync="isReservationNewOpen"
      :editable.sync="newReservationEditable"
      :data-params="reservationNewData"
      :types="typesOptions"
      :date="reservationCustomDate"
      :group="configOrganization"
      :groups="organizationsOptions"
      :facility="configFacility"
      :facilities="facilitiesOptions"
      :fields="fieldsOptions"
      :players-groups="playersGroupsOptions"
      :show-allow-video="!datePassed"
      :open-time="facilityOpenTime"
      :close-time="facilityCloseTime"
      @openClose="openCloseNew"
      @refreshEvents="onRefreshEvents"
      @initData="reservationNewData = {}"
    ></reservation-new> -->

    <!-- <reservation-detail
      :is-open.sync="isReservationDetailOpen"
      :data-params="calendarEventDetail"
      :types="typesOptions"
      :date-reservation="reservationCustomDate"
      :date-range="dateRangeStr"
      :range="reservationRange"
      :tab-day="tabDay"
      :group="configOrganization"
      :groups="organizationsOptions"
      :facility="configFacility"
      :facilities="facilitiesOptions"
      :fields="fieldsOptions"
      :field="fieldFilter"
      :duration="durationFilter"
      :players-groups="playersGroupsOptions"
      @openClose="openCloseDetail"
      @refreshEvents="onRefreshEvents"
      @refreshDetail="onRefreshEventDetail"
    ></reservation-detail> -->

    <share
      :is-open.sync="isDialogShare"
      :icon-size="28"
      :link="shareData.link"
      :sport="shareData.sport"
      :date="shareData.date"
      :start-time="shareData.startTime"
      :location="shareData.location"
      @open-close="openUpdateShare"
    />

    <v-navigation-drawer
      v-model="isFilterDrawerActive"
      width="250"
      mobile-breakpoint="sm"
      absolute
      touchless
      :right="$vuetify.rtl"
      :temporary="$vuetify.breakpoint.xsOnly"
      style="z-index: 2"
    >
      <filter-drawer @close-drawer="isFilterDrawerActive = false" />
    </v-navigation-drawer>

    <!-- New Reservation Drawer -->
    <v-navigation-drawer
      v-model="isReservationNewOpen"
      :right="!$vuetify.rtl"
      touchless
      app
      temporary
      :width="$vuetify.breakpoint.smAndDown ? '100%' : '75%'"
    >
      <reservation-new-form-view
        :is-open.sync="isReservationNewOpen"
        :editable.sync="newReservationEditable"
        :data-params="reservationNewData"
        :types="typesOptions"
        :date="reservationCustomDate"
        :group="configOrganization"
        :groups="organizationsOptions"
        :facility="configFacility"
        :facilities="facilitiesOptions"
        :fields="fieldsOptions"
        :players-groups="playersGroupsOptions"
        :onsite-payment-methods="onSitePaymentMethods"
        :show-allow-video="!datePassed"
        :open-time="facilityOpenTime"
        :close-time="facilityCloseTime"
        @openClose="openCloseNew"
        @refreshEvents="onRefreshEvents"
        @initData="reservationNewData = {}"
      />

      <!-- <reservation-new-form
        :is-open.sync="isReservationNewOpen"
        :editable.sync="newReservationEditable"
        :data-params="reservationNewData"
        :types="typesOptions"
        :date="reservationCustomDate"
        :group="configOrganization"
        :groups="organizationsOptions"
        :facility="configFacility"
        :facilities="facilitiesOptions"
        :fields="fieldsOptions"
        :players-groups="playersGroupsOptions"
        :onsite-payment-methods="onSitePaymentMethods"
        :show-allow-video="!datePassed"
        :open-time="facilityOpenTime"
        :close-time="facilityCloseTime"
        @openClose="openCloseNew"
        @refreshEvents="onRefreshEvents"
        @initData="reservationNewData = {}"
      ></reservation-new-form> -->
    </v-navigation-drawer>

    <!-- Show/Edit Reservation Form -->
    <v-navigation-drawer
      v-model="isReservationDetailOpen"
      :right="!$vuetify.rtl"
      touchless
      app
      temporary
      :width="$vuetify.breakpoint.smAndDown ? '100%' : '75%'"
    >
      <reservation-detail-view
        v-if="isReservationDetailOpen"
        :data-params="calendarEventDetail"
        @openClose="openCloseDetail"
        @refreshEvents="onRefreshEvents"
        @refreshDetail="onRefreshEventDetail"
        @share="onUpdateShare"
        @recurring="onRecurring"
      />

      <!-- <reservation-new-form
        :is-open.sync="isReservationDetailOpen"
        :editable.sync="newReservationEditable"
        :data-params="reservationNewData"
        :types="typesOptions"
        :date="reservationCustomDate"
        :group="configOrganization"
        :groups="organizationsOptions"
        :facility="configFacility"
        :facilities="facilitiesOptions"
        :fields="fieldsOptions"
        :players-groups="playersGroupsOptions"
        :onsite-payment-methods="onSitePaymentMethods"
        :show-allow-video="!datePassed"
        :open-time="facilityOpenTime"
        :close-time="facilityCloseTime"
        @openClose="openCloseNew"
        @refreshEvents="onRefreshEvents"
        @initData="reservationNewData = {}"
      ></reservation-new-form> -->
    </v-navigation-drawer>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
/* eslint-disable no-unused-expressions */
import { ref, computed, watch, onMounted, onUnmounted } from '@vue/composition-api'
import {
  mdiChevronLeft,
  mdiChevronRight,
  mdiMenu,
  mdiMenuDown,
  mdiPlus,
  mdiSearchWeb,
  mdiCheckboxBlank,
  mdiVideoOutline,
  mdiVideoOffOutline,
  mdiShareVariant,
  mdiContentCopy,
  mdiSync,
  mdiCancel,
} from '@mdi/js'
import { useUtils } from '@core/libs/i18n'
import { info } from '@core/utils/toasted'
import { title } from '@core/utils/filter'
import { isDatePassed, useRouter, hexToRgb, isDev } from '@core/utils'
import { formatHH, sumOffsetHours } from '@core/utils/reservation'
import useFilters from '@core/utils/useFilters'
import moment from 'moment'

// Local Components
// import Leyend from './components/Leyend.vue'
// import ReservationNew from './ReservationNew.vue'
// import ReservationDetail from './ReservationDetail.vue'
// import ReservationNewForm from './form/ReservationNewForm.vue'
import InsightsComponent from '@/views/insights/InsightsComponent.vue'
import Share from '@/views/components/share/Share.vue'
import ReservationNotification from './ReservationNotification.vue'
import ShareCopy from './components/ShareCopy.vue'
import Event from './components/Event.vue'
import FilterDrawer from './components/FilterDrawer.vue'
import ReservationDetailView from './detail/ReservationDetailView.vue'
import ReservationNewFormView from './form/ReservationNewView.vue'

import useReservations from './useReservations'

export default {
  components: {
    // Leyend,
    // ReservationNew,
    // ReservationDetail,
    // ReservationNewForm,
    ReservationNotification,
    InsightsComponent,
    ShareCopy,
    Share,
    Event,
    FilterDrawer,
    ReservationDetailView,
    ReservationNewFormView,
  },
  setup() {
    const { t } = useUtils()
    const { route } = useRouter()
    const { updateFilter, getFilterByModule } = useFilters()
    const {
      userData,
      dateFilter,
      min,
      nowDate,
      maxDate,

      activeCalendarView,
      calendarViewOptions,
      categories,
      tabs,
      dateStr,
      dateRangeStr,
      reservationRange,
      reservationCustomDate,
      intervalCount,
      firstTime,
      offsetHours,
      facilityOpenTime,
      facilityCloseTime,
      facilityOpen,

      refCalendar,
      calendarValue,
      calendarEvents,
      calendarEventDetail,
      calendarEventsNoReason,

      fieldsOptions,
      playersGroupsOptions,
      onSitePaymentMethods,
      typesOptions,
      statusOptions,
      fieldFilter,
      durationFilter,
      typesFilter,
      statusFilter,
      dateRangeStart,
      dateRangeEnd,
      tabDay,

      configOrganization,
      configFacility,
      organizationsOptions,
      facilitiesOptions,

      fetchEvents,
      fetchEventDetail,
      fetchWeekRange,
      fetchEventNoReason,
      fetchFields,
      fetchPlayersGroups,
      fetchOnSitePaymentMethods,
      getRGBColor,
    } = useReservations()

    const smallWidth = computed(() => document.documentElement.clientWidth < 768)

    // ————————————————————————————————————
    //* ——— Templare Ref
    // ————————————————————————————————————
    const isFilterDrawerActive = ref(false)
    const newReservationEditable = ref(false)
    const isReservationNewOpen = ref(false)
    const isReservationDetailOpen = ref(false)
    const reservationNewData = ref({})
    const datePassed = ref(false)
    const hasEvent = ref(false)
    const isPrevent = ref(false)
    const isInsightOpen = ref(false)
    const eventNoReasonInterval = ref(null)
    const regreshInterval = ref(null)
    const pauseRefresh = ref(false)
    const isDialogShare = ref(false)
    const shareData = ref({
      link: '',
      sport: '',
      date: '',
      startTime: '',
      location: '',
    })

    const modalCustomDate = ref(false)
    const customDateFilter = computed({
      get: () => {
        if (dateStr.value) return moment(dateStr.value).format('ddd, MMM D, YYYY')

        return ''
      },
      set: (val => {
        if (val) dateStr.value = val
      }),
    })

    const openCloseNew = value => {
      isReservationNewOpen.value = value
      if (!value) datePassed.value = false
    }

    const openCloseDetail = value => {
      isReservationDetailOpen.value = value
    }

    const openCloseInsight = value => {
      isInsightOpen.value = value
    }

    const openUpdateShare = async val => {
      isDialogShare.value = val
    }

    const onUpdateShare = item => {
      shareData.value.link = item.reservation_share_url
      shareData.value.sport = item.sport_name
      shareData.value.date = item.date_str
      shareData.value.startTime = item.time_str_short
      shareData.value.location = `${item.facility_name} • ${item.field_name}`

      openUpdateShare(true)
    }

    // ————————————————————————————————————
    //* ——— Calendar Value & Events
    // ————————————————————————————————————
    const computedTabs = computed(() => tabs.value)
    const toggleWeek = ref(null)

    const onChangeTab = async tab => {
      dateStr.value = tabs.value[tab].date_str
      updateFilter('views-reservations-schedule', 'date', dateStr.value)
      updateFilter('views-reservations-schedule', 'tab', tab)
      await fetchEvents(configFacility.value || 0)
    }

    const onChangeRange = async tab => {
      if (configFacility.value) await fetchEventNoReason(configFacility.value)
      await fetchWeekRange(tab)
      tabDay.value = 0
    }

    const onChangeDay = async (next, customDate = null) => {
      let newDay = ''
      if (customDate) {
        newDay = customDate
      } else if (next === 2) newDay = moment(dateStr.value).clone().add(1, 'day').format('YYYY-MM-DD')
      else newDay = moment(dateStr.value).clone().subtract(1, 'day').format('YYYY-MM-DD')

      dateStr.value = newDay
      if (configFacility.value) await fetchEventNoReason(configFacility.value)
      await fetchEvents(configFacility.value, newDay)
    }

    const onToggleWeek = e => {
      const result = new Date(dateFilter.value)
      if (e === 1) {
        if (result > min.value) {
          result.setDate(result.getDate() - 1)
          dateFilter.value = result.toISOString().substring(0, 10)
        }
      } else if (e === 2) {
        const mapMax = new Date(maxDate.value)
        if (result < mapMax) {
          result.setDate(result.getDate() + 1)
          dateFilter.value = result.toISOString().substring(0, 10)
        }
      } else {
        dateFilter.value = nowDate.value
      }
    }

    //
    //* ——— Calendar Event Handler ——————————————————
    //
    const onOpenEventDetail = async id => {
      await fetchEventDetail(id)
      isReservationDetailOpen.value = true
    }

    const sumarDia = currDate => {
      const fechaActual = new Date(currDate)
      fechaActual.setDate(fechaActual.getDate() + 1)
      const fechaNueva = fechaActual.toISOString().split('T')[0]

      return fechaNueva
    }

    const intervalFormat = e => formatHH(sumOffsetHours(e.date, e.time, offsetHours.value).hour)

    const updateDate = (date, time) => {
      let formatMilitaryTime = []
      const splitTime = time.split(':')
      const minute = parseInt(splitTime[1], 10)
      if (minute >= 0 && minute < 14) splitTime[1] = '00'
      if (minute >= 15 && minute < 29) splitTime[1] = '15'
      if (minute >= 30 && minute < 44) splitTime[1] = '30'
      if (minute >= 45 && minute < 59) splitTime[1] = '45'

      const newHour = sumOffsetHours(date, splitTime.join(':'), offsetHours.value)

      const formatTime = formatHH(newHour.hour)
      reservationNewData.value.start_time = formatTime

      formatMilitaryTime = newHour.militaryHour.split(':')
      const militaryHour = parseInt(formatMilitaryTime[0], 10)
      reservationCustomDate.value = date
      if (offsetHours.value > 0 && militaryHour >= 0 && militaryHour < offsetHours.value) {
        const newDate = sumarDia(date)
        reservationCustomDate.value = newDate
      }
    }

    const calenderHandleEventClick = async eventObj => {
      hasEvent.value = true
      if (!isPrevent.value) {
        updateDate(eventObj.date, '09:00')
        await onOpenEventDetail(eventObj.event.reservation_id)
      }
    }

    const calendarHandleDayCategotyClick = dayObj => {
      datePassed.value = isDatePassed(nowDate.value, dayObj.date)

      if (!hasEvent.value) {
        updateDate(dayObj.date, dayObj.time)
        reservationNewData.value.category = dayObj.category

        if (configFacility.value) isReservationNewOpen.value = true
        else if (!configFacility.value) info('Select facility')
      }
    }

    //
    //* ——— Event Actions ——————————————————
    //
    const onRefreshEvents = async () => {
      isReservationNewOpen.value = false
      newReservationEditable.value = false
      datePassed.value = false
      if (configFacility.value) await fetchEventNoReason(configFacility.value)
      await fetchEvents(configFacility.value || 0)
    }

    const onRefreshEventDetail = async id => {
      isReservationNewOpen.value = false
      newReservationEditable.value = false
      datePassed.value = false
      await fetchEventDetail(id)
    }

    const onNewReservation = () => {
      if (configFacility.value) {
        newReservationEditable.value = true
        updateDate(dateStr.value, '09:00')
        isReservationNewOpen.value = true
      } else if (!configFacility.value) info('Select facility')
    }

    const formatCurrentDayStr = (day, locale) => {
      moment.locale(locale)

      const today = moment().startOf('day')
      const inputDate = moment(day).startOf('day')

      // Comparar fechas
      if (inputDate.isSame(today, 'day')) {
        return t('tooltip.today')
      } if (inputDate.isSame(today.clone().subtract(1, 'days'), 'day')) {
        return t('tooltip.yesterday')
      } if (inputDate.isSame(today.clone().add(1, 'days'), 'day')) {
        return t('tooltip.tomorrow')
      }

      return moment(day).format('dddd')
    }

    const formatCurrentMonthDay = (day, locale) => {
      moment.locale(locale)

      return moment(day).format('MMM D')
    }

    const onShare = async item => {
      console.log('🚀 ~ onShare ~ item:', item)
    }
    const onRecurring = async item => {
      console.log('🚀 ~ onRecurring ~ item:', item)
    }

    // ————————————————————————————————————
    //* ——— Event Handler
    // ————————————————————————————————————
    watch(hasEvent, val => {
      if (val) {
        setTimeout(() => {
          hasEvent.value = false
        }, 200)
      }
    })

    watch(isPrevent, val => {
      if (val) {
        setTimeout(() => {
          isPrevent.value = false
        }, 200)
      }
    })

    watch([isReservationNewOpen], () => {
      if (isReservationNewOpen.value) pauseRefresh.value = true
      else pauseRefresh.value = false
    })

    watch([isReservationDetailOpen], () => {
      if (isReservationDetailOpen.value) pauseRefresh.value = true
      else pauseRefresh.value = false
    })

    onMounted(async () => {
      const { reservationParams } = route.value.params

      const filters = getFilterByModule('views-reservations-schedule')
      if (filters.date) dateStr.value = filters.date
      if (filters.tab) tabDay.value = filters.tab

      if (reservationParams) {
        dateStr.value = reservationParams.reservationDate
        dateRangeStr.value = reservationParams.rangeDate
        reservationRange.value = reservationParams.range
        tabDay.value = reservationParams.tabDay
        await fetchWeekRange(reservationRange.value, dateRangeStr.value)
        await onOpenEventDetail(reservationParams.reservationId)
      } else {
        await fetchWeekRange(0, dateStr.value)
      }

      customDateFilter.value = dateStr.value

      if (filters.type) typesFilter.value = filters.type

      if (configFacility.value) {
        await fetchFields(configFacility.value)
        await fetchPlayersGroups(configFacility.value)
        await fetchOnSitePaymentMethods(configFacility.value)
      }
      eventNoReasonInterval.value = setInterval(async () => {
        if (configFacility.value) await fetchEventNoReason(configFacility.value)
      }, 300000)

      regreshInterval.value = setInterval(async () => {
        if (configFacility.value && !pauseRefresh.value) await onRefreshEvents()
      }, 120000)
    })

    onUnmounted(() => {
      clearInterval(eventNoReasonInterval.value)
      clearInterval(regreshInterval.value)
    })

    return {
      // Template Refs
      refCalendar,
      smallWidth,

      isFilterDrawerActive,
      newReservationEditable,
      isReservationNewOpen,
      isReservationDetailOpen,
      reservationNewData,
      openCloseNew,
      openCloseDetail,
      isInsightOpen,
      isPrevent,
      openCloseInsight,
      dateRangeStart,
      dateRangeEnd,
      intervalCount,
      firstTime,
      offsetHours,
      facilityOpenTime,
      facilityCloseTime,
      facilityOpen,

      // Calendar View/Type
      datePassed,
      activeCalendarView,
      calendarViewOptions,

      // Data
      userData,
      tabDay,
      reservationRange,
      tabs,
      dateStr,
      dateRangeStr,
      reservationCustomDate,
      computedTabs,
      toggleWeek,
      onToggleWeek,
      onChangeRange,
      onChangeTab,
      onChangeDay,
      hexToRgb,
      title,
      isDev,
      isDialogShare,
      shareData,
      pauseRefresh,
      modalCustomDate,
      customDateFilter,

      fieldFilter,
      durationFilter,
      typesFilter,
      statusFilter,
      fieldsOptions,
      playersGroupsOptions,
      onSitePaymentMethods,
      typesOptions,
      statusOptions,
      categories,

      configOrganization,
      configFacility,
      organizationsOptions,
      facilitiesOptions,

      // Calendar Value & Events
      calendarValue,
      calendarEvents,
      calendarEventDetail,
      calendarEventsNoReason,
      onRefreshEvents,
      onRefreshEventDetail,
      onOpenEventDetail,
      onNewReservation,
      getRGBColor,
      openUpdateShare,
      onUpdateShare,
      formatCurrentDayStr,
      formatCurrentMonthDay,
      onShare,
      onRecurring,

      calenderHandleEventClick,
      calendarHandleDayCategotyClick,
      intervalFormat,
      updateFilter,

      // i18n
      t,

      // Icons
      icons: {
        mdiChevronLeft,
        mdiChevronRight,
        mdiMenu,
        mdiMenuDown,
        mdiPlus,
        mdiSearchWeb,
        mdiCheckboxBlank,
        mdiVideoOutline,
        mdiVideoOffOutline,
        mdiShareVariant,
        mdiContentCopy,
        mdiSync,
        mdiCancel,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.only-desktop {
  display: block !important;
}

.only-mobile {
  display: none !important;
}

@media (max-width: 768px) {
  .only-desktop {
    display: none !important;
  }

  .only-mobile {
    display: block !important;
  }
}
</style>

<style lang="scss">
@import '~@core/preset/preset/apps/calendar.scss';
</style>
<style lang="scss">
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: '';
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}

.v-calendar-category .v-calendar-daily_head-day-label,
.v-calendar-daily_head-weekday {
  display: none;
}

.border-event {
  border: 0.5px solid #ffffff;
  border-radius: 4px;
  position: relative;
}

.border-event-in {
  border-radius: 4px 0 0 4px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.theme--dark.v-calendar-events .v-event-timed {
  // height: 115px !important;
  margin-top: 2px;
  margin-bottom: 2px;
}

.v-calendar-category .v-calendar-category__columns .v-calendar-category__column-header,
.v-calendar-category .v-calendar-daily__day {
  min-width: 300px !important;
}

.v-calendar-daily__interval-text {
  font-size: 14px !important;
}
.v-calendar-category {
  background-color: #f1f1f1 !important;
}

.v-calendar-daily__intervals-body, .v-calendar-daily__intervals-head {
  background-color: #ffffff;
}
// .v-calendar-category .v-calendar-daily__body {
//   background-color: #f1f1f1;
// }

.container-days {
  display: flex;
}

.slide-day {
  .v-slide-group__content {
    justify-content: space-between;
  }
}

.v-btn--active::before {
  opacity: 0 !important;
}

.btn-arrows {
  max-width: 40px !important;
  min-width: 40px !important;
  max-height: 40px !important;
  min-height: 40px !important;
}

@media (max-width: 768px) {
  .container-days {
    display: flex;
  }

  .slide-day {
    width: 78%;
  }

  .v-calendar-daily__interval-text {
    font-size: 12px !important;
    font-weight: normal;
  }

  .v-calendar-category .v-calendar-category__columns .v-calendar-category__column-header,
  .v-calendar-category .v-calendar-daily__day {
    min-width: 210px !important;
  }
}
</style>
