<template>
  <div class="h-full">
    <!-- <div class="drawer-header d-flex align-center mb-0">
      <span class="font-weight-semibold text-base text--primary">{{ t('reservations.new_reservation') }}</span>
      <v-spacer></v-spacer>
      <v-btn
        icon
        small
        @click="$emit('openClose', false)"
      >
        <v-icon size="22">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div> -->

    <!-- <reservation-new-form-view /> -->

    <v-form
      ref="form"
      class="multi-col-validation px-5 pt-3 pb-10"
    >
      <v-row>
        <v-col
          cols="12"
          class="pb-0"
        >
          <label class="font-weight-medium">Date and Time</label>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-if="!computedEditable"
            v-model="reservationData.reservation_date"
            class="text-body-1"
            :label="t('transactions.date')"
            color="primary"
            outlined
            dense
            persistent-hint
            :rules="[required]"
            :readonly="!computedEditable"
          ></v-text-field>

          <v-menu
            v-else
            v-model="modalDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="reservationData.reservation_date"
                :label="t('transactions.date')"
                outlined
                readonly
                dense
                class="text-body-1"
                :rules="[required]"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="reservationData.reservation_date"
              class="text-body-1"
              color="secondary"
              :min="minDate"
              @input="modalDate = false"
              @change="onSelectDate"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-autocomplete
            v-model="reservationData.start_time"
            :label="t('transactions.start_time')"
            :items="computedMinutes"
            item-text="value"
            item-value="value"
            dense
            outlined
            class="text-body-1"
            :append-icon="icons.mdiMenuDown"
            :rules="[required]"
            @change="onSelectStartTime"
          ></v-autocomplete>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-autocomplete
            v-model="reservationData.reservation_time"
            dense
            outlined
            class="text-body-1"
            :label="t('video_details.duration')"
            :items="minuteOptions"
            item-text="text"
            item-value="value"
            hide-details
            :rules="[required]"
            @change="onSelectDuration"
          ></v-autocomplete>
          <!-- <span
              v-if="minuteWarnText"
              class="error--text mt-auto"
            >
              {{ `${minuteWarnText}` }}
            </span> -->
        </v-col>

        <v-col
          v-if="minuteWarnText"
          cols="12"
        >
          <v-alert
            class="mb-0"
            type="warning"
          >
            <template #prepend>
              <v-icon>
                {{ icons.mdiAlert }}
              </v-icon>
            </template>
            {{ `${minuteWarnText}` }}
          </v-alert>
        </v-col>

        <v-col
          cols="12"
          class="d-flex align-center"
          fluid
        >
          <v-checkbox
            v-model="reservationData.make_recurring"
            :label="t('videos.make_recurring')"
            true-value="Y"
            false-value="N"
            color="secondary"
            class="ma-0 pa-0 text-body-1 font-weight-medium"
            hide-details
            @change="onSelectMakeRecurring"
          />
        </v-col>

        <v-col
          v-if="reservationData.make_recurring === 'Y'"
          cols="12"
          md="4"
        >
          <v-select
            v-model="reservationData.repeat_n_weeks"
            dense
            outlined
            class="text-body-1"
            :label="t('reservations.frequency')"
            :items="recurringFrequencyOptions"
            item-text="text"
            item-value="value"
            :rules="[required]"
          ></v-select>
        </v-col>

        <v-col
          v-if="reservationData.make_recurring === 'Y'"
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="reservationData.start_date"
            class="text-body-1"
            :label="t('transactions.from_date')"
            color="primary"
            outlined
            dense
            persistent-hint
            disabled
          />
        </v-col>

        <v-col
          v-if="reservationData.make_recurring === 'Y'"
          cols="12"
          md="4"
        >
          <v-menu
            v-model="modalTo"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="reservationData.end_date"
                :label="t('transactions.to_date')"
                outlined
                readonly
                dense
                clearable
                class="text-body-1"
                :rules="[required]"
                hide-details
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="reservationData.end_date"
              class="text-body-1"
              color="secondary"
              :min="reservationData.start_date"
              @input="modalTo = false"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col
          v-if="reservationData.make_recurring === 'Y' && !reservationData.end_date"
          cols="12"
        >
          <v-alert
            class="mb-0"
            type="warning"
          >
            <template #prepend>
              <v-icon>
                {{ icons.mdiAlert }}
              </v-icon>
            </template>
            {{ `${t('transactions.to_date')} ${t('required.null')}` }}
          </v-alert>
        </v-col>

        <v-col
          v-if="reservationData.make_recurring === 'Y'"
          cols="12"
          class="d-flex align-center"
        >
          <v-icon
            color="info"
            class="mr-1"
          >
            {{ icons.mdiInformationOutline }}
          </v-icon>
          <label>
            End after <strong>52</strong> bookings
          </label>
        </v-col>

        <v-col
          cols="12"
          class="pb-0"
        >
          <label class="font-weight-medium">{{ t('fields.field') }}</label>
        </v-col>

        <v-col cols="12">
          <custom-radios
            v-if="fielsSelected"
            :radio-content="fieldList"
            :selected-radio.sync="fielsSelected"
            :slide-display="true"
            :grid-column="{ cols: '12', sm: '3' }"
            @update:selectedRadio="onSelectField"
          />
        </v-col>

        <v-col
          cols="12"
          class="pb-0"
        >
          <label class="font-weight-medium">Add-ons</label>
        </v-col>

        <v-col
          cols="12"
          md="3"
          @click="onIncludeAddOns"
        >
          <label
            class="label-custom-input custom-checkbox rounded cursor-pointer"
            :class="isIncludeAddOns === 'Y' ? 'active' : ''"
            @click="onIncludeAddOns"
          >
            <div>
              <v-checkbox
                v-model="isIncludeAddOns"
                true-value="Y"
                false-value="N"
                class="ma-0 pa-0"
                color="secondary"
                hide-details
                @change="calFullPrice"
              />
            </div>
            <div class="flex-grow-1">
              <div class=" align-center mb-1">
                <h6 class="cr-title text-base">
                  {{ includeAddOnsOption.title }}
                </h6>
                <v-spacer />
              </div>
              <p class="text-sm mb-0">
                {{ includeAddOnsOption.subtitle }}
              </p>
            </div>
          </label>
        </v-col>

        <v-col
          cols="12"
          class="pb-0"
        >
          <label class="font-weight-medium">{{ t('reservations.pricing_method') }}</label>
        </v-col>

        <v-col
          cols="12"
          md="auto"
          class="d-flex align-center"
        >
          <v-btn-toggle
            v-model="selectedPricingType"
            mandatory
            block
            outlined
            active-class="white--text"
          >
            <v-btn
              outlined
              class="black--text"
              @click="onSelectPricingType('F')"
            >
              {{ t('reservations.flat_rate') }}
            </v-btn>

            <v-btn
              outlined
              class="black--text"
              @click="onSelectPricingType('P')"
            >
              {{ t('reservations.per_participant') }}
            </v-btn>
          </v-btn-toggle>
        </v-col>

        <v-col
          cols="12"
          md="3"
          class="d-flex align-center"
        >
          <v-text-field
            v-model="reservationData.price"
            :label="t('transactions.price')"
            class="text-body-1"
            outlined
            prefix="$"
            dense
            hide-details
            :rules="[required, regexValidator(reservationData.price, '^[0-9]{0,9}(\.?[0-9]{0,2})?$', 'Incorrect format')]"
            @input="e => {
              if (!charUnique(e, '.')) {
                reservationData.price = null
                fullPrice = 0
              } else {
                calFullPrice()
              }
            }"
            @keypress="onlyNumberDot"
          />
        </v-col>

        <v-col
          cols="12"
        >
          <span><span class="font-weight-bold">{{ `${formatCurrency(fullPrice)}` }}</span>{{ ` ${t('reservations.inc_taxes_add_ons')}` }}</span>
        </v-col>

        <v-col
          v-if="reservationData.price === null"
          cols="12"
        >
          <v-alert
            class="mb-0"
            type="warning"
          >
            <template #prepend>
              <v-icon>
                {{ icons.mdiAlert }}
              </v-icon>
            </template>
            {{ `${t('transactions.price')} ${t('required.null')}` }}
          </v-alert>
        </v-col>

        <v-col
          cols="12"
          class="pb-0"
        >
          <label class="font-weight-medium">{{ t('reservations.participants') }}</label>
        </v-col>

        <v-col
          cols="12"
          class="d-flex align-center"
        >
          <v-icon
            color="info"
            class="mr-1"
          >
            {{ icons.mdiInformationOutline }}
          </v-icon>
          <label>
            {{ t('reservations.tota_includ_taxes_addons') }}
          </label>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-autocomplete
            ref="autoContact"
            v-model="contactSelectedId"
            :label="t('reservations.contact')"
            :items="contacts"
            item-text="fullname"
            item-value="id"
            :loading="searchLoading"
            :search-input.sync="searchQuery"
            clearable
            hide-selected
            dense
            outlined
            chips
            :append-icon="null"
            :rules="[required]"
            hide-details
            @change="onSelectContact"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  <a
                    href="javascript:void(0);"
                    class="secondary--text text-decoration-none"
                    @click="addContact"
                  >
                    {{ t('reservations.add_new_contact') }}
                  </a>
                </v-list-item-title>
              </v-list-item>
            </template>

            <template v-slot:selection="{ attr, on, item, selected }">
              <v-chip
                v-bind="attr"
                :input-value="selected"
                class="text-body-1"
                v-on="on"
              >
                <span v-text="`${item.first_name || ''} ${item.last_name || ''}`"></span>
              </v-chip>
            </template>

            <template v-slot:item="{ item }">
              <v-list-item-avatar
                v-if="item.id !== 0"
                class="my-2 mr-2"
                size="28"
              >
                <v-avatar
                  color="primary"
                  :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
                  size="28"
                >
                  <v-img
                    v-if="item.avatar"
                    :src="item.avatar"
                  ></v-img>
                  <span
                    v-else
                    class="font-weight-medium text-body-2"
                  >{{ avatarText(`${item.first_name || ''} ${item.last_name || ''}`) }}</span>
                </v-avatar>
              </v-list-item-avatar>

              <v-list-item-content
                v-if="item.id !== 0"
                class="my-2"
              >
                <v-list-item-title v-text="`${item.first_name || ''} ${item.last_name || ''}`"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-content
                v-else
                class="my-2"
              >
                <v-list-item-title>
                  <a
                    href="javascript:void(0);"
                    class="secondary--text text-decoration-none"
                  >
                    {{ t('reservations.add_new_contact') }}
                  </a>
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>

        <v-col
          v-if="!contactSelectedId"
          cols="12"
        >
          <v-alert
            class="mb-0"
            type="warning"
          >
            <template #prepend>
              <v-icon>
                {{ icons.mdiAlert }}
              </v-icon>
            </template>
            {{ `${t('reservations.contact')} ${t('required.null')}` }}
          </v-alert>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          class="d-flex justify-start"
        >
          <v-btn
            color="secondary"
            class="me-3"
            @click="saveAction('Y')"
          >
            {{ t('tooltip.create') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="() => {
              cancelAction()
              $emit('update:is-open', false)
            }"
          >
            {{ t('tooltip.cancel') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <v-dialog
      v-model="isModalOpen"
      max-width="420px"
    >
      <v-card
        class="pa-3"
        rounded="10"
        style="width: 100%"
      >
        <v-form ref="formDialog">
          <v-app-bar
            flat
            color="rgba(0, 0, 0, 0)"
            dense
            class="px-4"
          >
            <v-toolbar-title class="text-h6 white--text pl-0">
              {{ t('notify_players.other_phone_numbers') }}
            </v-toolbar-title>
          </v-app-bar>

          <v-card-text class="pa-0 mt-5 mb-7 justify-center d-flex flex-column">
            <v-row class="justify-center align-center">
              <v-col
                v-for="(phoneInput, index) in phoneInputs"
                :key="index"
                cols="12"
                md="10"
                class="py-0"
              >
                <div class="d-flex justify-space-between align-items-start">
                  <v-autocomplete
                    v-model="phoneInput.code"
                    dense
                    outlined
                    :items="phoneCodeOptions"
                    item-text="text"
                    item-value="value"
                    style="
                      width: 35%;
                    "
                    :append-icon="icons.mdiMenuDown"
                    hide-details
                    :rules="[required]"
                    @change="() => phoneInput.value = null"
                  >
                    <template v-slot:item="{ item }">
                      <img
                        :src="item.image"
                        :alt="item.value"
                        width="20"
                        height="auto"
                        class="mr-2"
                      >
                      {{ item.text }}
                    </template>
                  </v-autocomplete>

                  <v-text-field
                    v-model="phoneInput.value"
                    :label="t('notify_players.phone')"
                    dense
                    outlined
                    class="pl-2"
                    :class="{ 'mb-2': index > 0 }"
                    :disabled="!phoneInput.code"
                    :hide-details="index > 0"
                    :rules="(index === 0) ? [required, validatePhones(phoneInput.value, phoneInput)] : [validatePhones(phoneInput.value, phoneInput)]"
                    :append-icon="index > 0 ? icons.mdiClose : null"
                    @click:append="deletePhoneNumber(index)"
                    @keydown.enter.prevent="addPhoneNumber"
                  ></v-text-field>
                </div>
              </v-col>

              <v-col
                cols="12"
                md="10"
                class="py-0"
              >
                <a
                  href="javascript:void(0);"
                  class="text-body-1 ma-0 btn-add secondary--text text-decoration-none"
                  @click="addPhoneNumber"
                >
                  + {{ t('notify_players.add_phone') }}
                </a>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="d-flex flex-column justify-center py-3">
            <div class="d-flex justify-space-between">
              <v-btn
                class="text-xs"
                color="lightGray"
                dark
                width="30%"
                rounded
                outlined
                @click="isModalOpen = false"
              >
                {{ t('tooltip.close') }}
              </v-btn>
            </div>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="isContactModalOpen"
      max-width="600px"
    >
      <v-card
        class="pa-3"
        rounded="10"
        style="width: 100%"
      >
        <v-form ref="formContactDialog">
          <v-app-bar
            flat
            color="rgba(0, 0, 0, 0)"
            dense
            class="px-4"
          >
            <v-toolbar-title
              class="text-h6 pl-0"
              :class="{ 'white--text': isDark }"
            >
              {{ reservationData.reservation_type === 'regular' ? t('notify_players.organizer') : t('reservations.contact') }}
            </v-toolbar-title>
          </v-app-bar>

          <v-card-text class="pa-0 mt-5 mb-7 justify-center d-flex flex-column">
            <v-row
              justify="center"
              align="center"
            >
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="contactData.first_name"
                  :label="t('users.firstname')"
                  dense
                  outlined
                  :rules="[required]"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="contactData.last_name"
                  :label="t('users.lastname')"
                  dense
                  outlined
                  :rules="[]"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="contactData.email"
                  :label="t('users.email')"
                  dense
                  outlined
                  :rules="contactData.email ? [emailValidator] : []"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <div class="d-flex justify-space-between">
                  <v-autocomplete
                    v-model="phoneCode"
                    dense
                    outlined
                    :items="phoneCodeOptions"
                    item-text="text"
                    item-value="value"
                    style="
                      width: 35%;
                    "
                    :append-icon="icons.mdiMenuDown"
                    hide-details
                    :rules="[required]"
                  >
                    <template v-slot:item="{ item }">
                      <img
                        :src="item.image"
                        :alt="item.value"
                        width="20"
                        height="auto"
                        class="mr-2"
                      >
                      {{ item.text }}
                    </template>
                  </v-autocomplete>

                  <v-text-field
                    v-model="phoneNumber"
                    :label="t('users.phone')"
                    dense
                    outlined
                    :disabled="!phoneCode"
                    class="pl-1"
                    :rules="[required, validatePhone(phoneNumber, phoneCode)]"
                  ></v-text-field>
                </div>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="d-flex flex-column justify-center py-3">
            <div class="d-flex justify-space-between">
              <v-btn
                class="text-xs mr-3"
                color="secondary"
                dark
                width="30%"
                rounded
                outlined
                @click="onCreateContact"
              >
                {{ t('tooltip.save') }}
              </v-btn>

              <v-btn
                class="text-xs"
                color="lightGray"
                dark
                width="30%"
                rounded
                outlined
                @click="() => {
                  isContactModalOpen = false
                  formContactDialog.reset()
                }"
              >
                {{ t('tooltip.close') }}
              </v-btn>
            </div>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <search-players-group
      :is-open.sync="isSearchGroupModalOpen"
      :facility="facility"
      :facilities="facilities"
      :players-group-data.sync="playersGroupData"
    />
  </div>
</template>

<script>
/* eslint-disable object-curly-newline */
import { ref, computed, watch, onMounted, onBeforeMount, getCurrentInstance } from '@vue/composition-api'
import {
  mdiClockTimeFourOutline,
  mdiTimerOutline,
  mdiCalendarBlankOutline,
  mdiCalendar,
  mdiMenuDown,
  mdiClose,
  mdiPencilOutline,
  mdiMagnify,
  mdiEyeOutline,
  mdiInformationOutline,
  mdiAlert,
} from '@mdi/js'
import { useUtils } from '@core/libs/i18n'
import {
  createReservation,
  createRecurringReservation,
  getContactFacilitySearch,
  getContactCategoryActiveFacility,
  createContact,
  getReservationAvailabilityTime,
} from '@api'
import { success, error } from '@core/utils/toasted'
import useCryptoJs from '@core/utils/useCryptoJs'
import {
  required,
  integerValidator,
  regexValidator,
  charUnique,
  between,
  emailValidator,
} from '@core/utils/validation'
import { formatCurrency, trimSpace } from '@core/utils'
import { onlyNumberDot, onlyNumbers } from '@core/utils/functionsHelpers'
import { avatarText, title } from '@core/utils/filter'
import moment from 'moment'
import Swal from 'sweetalert2'
import useCountry from '@core/utils/useCountry'
import usePhone from '@core/utils/usePhone'
import { DURATION_OPTIONS, TIME_OPTIONS, sumMinutesOptions, convertHour12To24 } from '@core/utils/reservation'
import parsePhoneNumber from 'libphonenumber-js'
import useAppConfig from '@core/@app-config/useAppConfig'

import Vue from 'vue'
import { VueMaskDirective } from 'v-mask'

// import CustomCheckboxes from '@core/components/custom-checkboxes/CustomCheckboxes.vue'
// import ReservationNewFormView from './ReservationNewView.vue'
import CustomRadios from '@core/components/custom-radios/CustomRadios.vue'
import searchPlayersGroup from '../components/searchPlayersGroup.vue'

export default {
  name: 'ReservationNewForm',
  components: {
    // ReservationNewFormView,
    searchPlayersGroup,
    CustomRadios,

    // CustomCheckboxes,
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
      required: true,
    },
    types: {
      type: Array,
      default: () => [],
      required: true,
    },
    openTime: {
      type: String,
      default: '00:00',
      required: true,
    },
    closeTime: {
      type: String,
      default: '23:29',
      required: true,
    },
    date: {
      type: String,
      default: '',
      required: true,
    },
    dataParams: {
      type: Object,
      default: () => {},
      required: true,
    },
    groups: {
      type: Array,
      default: () => [],
      required: true,
    },
    group: {
      type: Number,
      default: 0,
      required: true,
    },
    facilities: {
      type: Array,
      default: () => [],
      required: true,
    },
    facility: {
      type: Number,
      default: 0,
      required: true,
    },
    fields: {
      type: Array,
      default: () => [],
      required: true,
    },
    playersGroups: {
      type: Array,
      default: () => [],
      required: true,
    },
    onsitePaymentMethods: {
      type: Array,
      default: () => [],
      required: true,
    },
    showAllowVideo: {
      type: Boolean,
      default: true,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t, tInContext } = useUtils()
    const { userData } = useCryptoJs()
    const { getCountryCode } = useCountry()
    const { phoneCodeOptions, validatePhones } = usePhone()
    const { isDark } = useAppConfig()
    const vm = getCurrentInstance().proxy

    // ————————————————————————————————————
    //* ——— Form Validator
    // ————————————————————————————————————

    const form = ref(null)

    const modalDate = ref(false)
    const modalStartTime = ref(false)
    const modalFrom = ref(false)
    const modalTo = ref(false)
    const dateFrom = ref(null)
    const dateTo = ref(null)
    const dateNow = ref(
      new Date(new Date()).toLocaleString('sv-SE', {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    )
    const maxDate = ref(
      new Date(+new Date() + 12096e5).toLocaleString('sv-SE', {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    )
    const minDate = ref(
      new Date(+new Date() - 12096e5).toLocaleString('sv-SE', {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }),
    )
    const currentDate = ref(new Date())

    const computedMinutes = computed(() => sumMinutesOptions(props.openTime, props.closeTime))

    const weekdaysShort = computed(() => [
      { text: t('weekdays_short.mon'), value: 0 },
      { text: t('weekdays_short.tue'), value: 1 },
      { text: t('weekdays_short.wed'), value: 2 },
      { text: t('weekdays_short.thu'), value: 3 },
      { text: t('weekdays_short.fri'), value: 4 },
      { text: t('weekdays_short.sat'), value: 5 },
      { text: t('weekdays_short.sun'), value: 6 },
    ])

    const fielsSelected = ref('')
    const fieldList = ref([])

    const selectedPricingType = ref(0)
    const includeAddOns = ref(['incline_add_ons'])
    const isIncludeAddOns = ref('Y')
    const includeAddOnsOption = ref({
      title: t('reservations.video_recording'),
      subtitle: '$0.00',
      value: 'incline_add_ons',
    })
    const includeAddOnsContent = computed(() => [includeAddOnsOption.value])
    const paymentTypeSelected = ref('F')
    const paymentTypeList = ref([
      {
        desc: null,
        title: 'One Payment',
        value: 'F',
      },
      {
        desc: null,
        title: 'Split Payment',
        value: 'P',
      },
    ])

    const computedDataParams = computed(() => props.dataParams)
    const computedEditable = computed(() => props.editable)
    const computedDate = computed(() => props.date)
    const computedGroup = computed(() => props.group || null)
    const computedFacility = computed(() => props.facility || null)
    const groupsOptions = computed(() => props.groups)
    const facilitiesOptions = computed(() => props.facilities)
    const fieldsOptions = computed(() => props.fields)
    const playersGroupsOptions = computed(() => props.playersGroups)
    const computedOnSitePaymentMethods = computed(() => props.onSitePaymentMethods)
    const computedIsAdmin = computed(() => userData.value.role === 'A')
    const computedIsOpen = computed({
      set: val => emit('update:is-open', val),
      get: () => props.isOpen,
    })

    const formDialog = ref(null)
    const isModalOpen = ref(false)
    const initCode = ref('')
    const phoneInputs = ref([
      { value: null, code: null, phone: null },
    ])
    const computedPhoneNumbers = computed(() => phoneInputs.value.filter(e => e.phone).map(e => e.phone).join(', '))

    const formContactDialog = ref(null)
    const contacts = ref([])
    const contactSelectedId = ref(null)
    const categories = ref([])
    const autoContact = ref(null)
    const isContactModalOpen = ref(false)
    const contactSelected = ref(null)
    const contactData = ref({
      first_name: null,
      last_name: null,
      phone_code: null,
      phone_prefix: null,
      phone_number: null,
      email: null,
      status: 'A',
      categories_ids: [],
    })
    const computedContacts = computed(() => {
      if (contactSelected.value) return `${contactSelected.value.first_name || ''} ${contactSelected.value.last_name || ''}`

      return null
    })
    const searchLoading = ref(false)
    const searchQuery = ref(null)
    const timerSearch = ref(null)
    const phoneCode = ref('')
    const phoneNumber = ref('')

    const showOnsitePayment = ref(false)
    const selectedOnsitePayment = ref(null)
    const isSearchGroupModalOpen = ref(false)
    const playersGroupData = ref([])
    const computedPlayersGroup = computed({
      get: () => playersGroupData.value.map(e => e.circle_name).join(', '),
      set: val => {
        if (val) playersGroupData.value = val
        else playersGroupData.value = []
      },
    })

    const fieldSelected = ref(null)

    const timer = ref(null)
    const fullPrice = ref(null)
    const minuteWarnText = ref(null)
    const endDateWarnText = ref(null)
    const contactWarnText = ref(null)
    const minuteOptions = ref(DURATION_OPTIONS)
    const timeOptions = ref(TIME_OPTIONS)
    const recurringFrequencyOptions = computed(() => [
      { text: t('billing_cycle_types.weekly'), value: 1 },
      { text: t('billing_cycle_types.every_two_weeks'), value: 2 },
    ])
    const skillLevelOptions = computed(() => [
      { text: t('skill_lavels.rookie'), value: 'rookie' },
      { text: t('skill_lavels.intermediate'), value: 'intermediate' },
      { text: t('skill_lavels.pro'), value: 'pro' },
    ])

    const initialData = {
      group_id: null,
      facility_id: null,
      field_id: null,
      circle_ids: null,
      user_console_id: null,
      reservation_type: null,
      reservation_date: null,
      start_time: null,
      start_date: null,
      end_date: null,
      reservation_time: 60,
      include_video: 'Y',
      price: null,
      players_qty: null,
      weekdays: [],
      repeat_n_weeks: 1,
      make_recurring: 'N',
      phone_numbers_list: [],
      contact_id: null,
      payment_type: 'F',
    }
    const reservationData = ref(initialData)

    const postData = ref({
      group_id: null,
      facility_id: null,
      field_id: null,
      circle_ids: null,
      user_console_id: null,
      reservation_type: null,
      reservation_date: null,
      start_time: null,
      start_date: null,
      end_date: null,
      reservation_time: 60,
      include_video: 'Y',
      price: null,
      players_qty: null,
      weekdays: [],
      repeat_n_weeks: 1,
      make_recurring: 'N',
      phone_numbers_list: [],
      contact_id: null,
      payment_type: 'F',
    })

    const getStartTime24 = () => {
      let st = ''
      if (reservationData.value.start_time.includes('AM') || reservationData.value.start_time.includes('PM')) {
        st = convertHour12To24(reservationData.value.start_time)
      } else {
        st = reservationData.value.start_time
      }

      return st
    }

    const onSelectType = type => {
      if (type === 'pick_up') reservationData.value.group_id = null
      else if (userData.value && userData.value.role !== 'A') reservationData.value.group_id = userData.value.group_id
      else reservationData.value.group_id = computedGroup.value

      if (type !== 'group') {
        reservationData.value.circle_ids = []
        computedPlayersGroup.value = []
      }

      if (type === 'pick_up' || type === 'group') {
        reservationData.value.contact_id = null
      }
    }

    const calPrice = () => Math.round((reservationData.value.reservation_time / 60) * fieldSelected.value.booking_hourly_price * 100) / 100

    const calFullPrice = () => {
      if (timer.value) clearTimeout(timer.value)
      timer.value = setTimeout(() => {
        if (fieldSelected.value) {
          let mapPrice = calPrice()

          let videoPrice = 0
          if (reservationData.value.include_video === 'Y') videoPrice = fieldSelected.value.hourly_pricing

          if (reservationData.value.payment_type === 'P') {
            videoPrice /= fieldSelected.value.players_qty
            mapPrice /= fieldSelected.value.players_qty
          }

          reservationData.value.price = Math.round(mapPrice * 100) / 100
          fullPrice.value = mapPrice + Math.round(videoPrice * 100) / 100
        } else {
          fullPrice.value = 0
          reservationData.value.price = 0
        }
      }, 300)
    }

    const onIncludeAddOns = () => {
      if (isIncludeAddOns.value === 'Y') isIncludeAddOns.value = 'N'
      else isIncludeAddOns.value = 'Y'

      reservationData.value.include_video = isIncludeAddOns.value

      calFullPrice()
    }

    const filterDurations = async field => {
      const resp = await getReservationAvailabilityTime(field, reservationData.value.reservation_date, getStartTime24())
      if (resp.ok && resp.data) {
        minuteWarnText.value = null
        minuteOptions.value = DURATION_OPTIONS.filter(e => resp.data.includes(e.value))
        reservationData.value.reservation_time = minuteOptions.value.at(0).value

        calFullPrice()
      } else {
        minuteWarnText.value = resp.message.text
        minuteOptions.value = []
        reservationData.value.reservation_time = null
        reservationData.value.price = 0
        fullPrice.value = 0
      }
    }

    // eslint-disable-next-line no-unused-vars
    const onSelectDuration = duration => {
      // const mapPrice = (duration / 60) * fieldSelected.value.booking_hourly_price
      // reservationData.value.price = Math.floor(mapPrice)
      // const mapUserPrice = (duration / 60) * fieldSelected.value.hourly_pricing
      // fullPrice.value = mapPrice + mapUserPrice + ((mapPrice + mapUserPrice) * (fieldSelected.value.facility.tax / 100))

      calFullPrice()
    }

    const onSelectMakeRecurring = recurring => {
      if (recurring !== 'Y') {
        reservationData.value.start_date = null
        reservationData.value.end_date = null
        reservationData.value.repeat_n_weeks = 1
        reservationData.value.weekdays = []
      } else {
        const momentData = moment(reservationData.value.reservation_date)
        const day = momentData.day()
        momentData.add(1, 'y')
        reservationData.value.weekdays = [
          day === 0 ? 6 : (day - 1),
        ]
        reservationData.value.start_date = reservationData.value.reservation_date
        reservationData.value.end_date = momentData.format('YYYY-MM-DD')
      }
    }

    const onSelectPricingType = async type => {
      reservationData.value.payment_type = type

      calFullPrice()
    }

    const onSelectField = async field => {
      reservationData.value.field_id = field
      const finded = fieldsOptions.value.find(e => e.id === field)
      if (finded) {
        fieldSelected.value = finded
        reservationData.value.players_qty = finded.players_qty
        includeAddOnsOption.value.subtitle = `$${finded.hourly_pricing}`

        // fullPrice.value = finded.booking_hourly_price + finded.hourly_pricing
        onSelectPricingType(reservationData.value.payment_type)
        calFullPrice()
      }

      await filterDurations(field)
    }

    const onSelectAddOns = async selected => {
      reservationData.value.include_video = selected.includes('incline_add_ons') ? 'Y' : 'N'
      calFullPrice()
    }

    const onSelectStartTime = async () => {
      await filterDurations(reservationData.value.field_id)
    }

    const allowedMinutes = m => m % 15 === 0

    const allowedHours = h => {
      if (dateNow.value === reservationData.value.reservation_date) return h >= currentDate.value.getHours()

      return true
    }

    const onSelectDate = async () => {
      await filterDurations(reservationData.value.field_id)
    }

    const addPhoneNumber = () => {
      if (phoneInputs.value.length < 30) {
        phoneInputs.value.push({ value: null, code: initCode.value, phone: null })
      }
    }

    const deletePhoneNumber = index => {
      phoneInputs.value.splice(index, 1)
    }

    const validatePhone = (e, code) => {
      if (code && e) {
        const phoneNumberJS = parsePhoneNumber(e, code)
        if (phoneNumberJS) {
          if (phoneNumberJS.isValid()) {
            contactData.value.phone_number = phoneNumberJS.number
            contactData.value.phone_code = phoneNumberJS.country
            contactData.value.phone_prefix = `+${phoneNumberJS.countryCallingCode}`

            return true
          }
        }
      }

      return 'Invalid number'
    }

    watch([isModalOpen], () => {
      if (isModalOpen.value) {
        phoneInputs.value[0].code = initCode.value
      }
    })

    const onAddContact = item => {
      reservationData.value.contact_id = item.id
      contacts.value = [item]
      contactSelected.value = item
      contactSelectedId.value = item.id
      isContactModalOpen.value = false
      if (formContactDialog.value) formContactDialog.value.reset()
    }

    const onCreateContact = async () => {
      if (formContactDialog.value.validate()) {
        const bodyContact = {
          first_name: contactData.value.first_name ? title(trimSpace(contactData.value.first_name)) : null,
          last_name: contactData.value.last_name ? title(trimSpace(contactData.value.last_name)) : null,
          phone_code: contactData.value.phone_code,
          phone_prefix: contactData.value.phone_prefix,
          phone_number: contactData.value.phone_number,
          email: contactData.value.email,
          status: 'A',
          categories_ids: contactData.value.categories_ids,
          facility_id: computedFacility.value,
        }
        const resp = await createContact(bodyContact)
        if (resp.ok) {
          bodyContact.id = resp.data.contact_id
          onAddContact(bodyContact)
          success(resp.message)
        } else {
          error(resp.message.text)
        }
      }
    }

    const fetchContacts = async () => {
      if (searchQuery.value) {
        searchLoading.value = true
        const response = await getContactFacilitySearch(computedFacility.value || 0, searchQuery.value)
        if (response.ok && response.data.length) {
          const newContact = [{
            id: 0,
            first_name: searchQuery.value,
            last_name: searchQuery.value,
            fullname: searchQuery.value,
            phone_number: searchQuery.value,
            email: searchQuery.value,
          }]
          contacts.value = newContact.concat(response.data)
        } else contacts.value = []
        searchLoading.value = false
      }
    }

    const getDataBySearch = async () => {
      clearTimeout(timerSearch.value)
      timerSearch.value = setTimeout(async () => {
        await fetchContacts()
      }, 1000)
    }

    const addContact = () => {
      isContactModalOpen.value = true
      if (formContactDialog.value) formContactDialog.value.resetValidation()
      phoneCode.value = initCode.value
      if (searchQuery.value) {
        const splitSearchQuery = searchQuery.value.split(' ')
        const first = splitSearchQuery.at(0)
        const last = splitSearchQuery.slice(1).join(' ')
        if (first) contactData.value.first_name = splitSearchQuery.at(0)
        if (last) contactData.value.last_name = splitSearchQuery.slice(1).join(' ')
      }
    }

    const onSelectContact = val => {
      if (val) {
        const itemDelected = contacts.value.find(e => e.id === val)
        onAddContact(itemDelected)
      } else if (val === 0) {
        contactSelectedId.value = null

        contacts.value = []
        autoContact.value.reset()
        addContact()
      } else {
        contactSelectedId.value = null
        contacts.value = []
      }
    }

    watch([isContactModalOpen], async () => {
      if (isContactModalOpen.value) {
        const responseCategories = await getContactCategoryActiveFacility(computedFacility.value || 0)
        categories.value = responseCategories.data
      } else {
        // searchQuery.value = ''
        formContactDialog.value.reset()
      }
    })

    watch([searchQuery], async () => {
      if (contactSelectedId.value) {
        const firSearch = searchQuery.value
        contactSelectedId.value = null
        setTimeout(() => { searchQuery.value = firSearch }, 50)
      }
      await getDataBySearch()
    })

    watch([computedIsOpen], async () => {
      if (computedIsOpen.value) {
        selectedPricingType.value = 0
        if (userData.value && userData.value.role !== 'A') reservationData.value.group_id = userData.value.group_id
        else reservationData.value.group_id = computedGroup.value
        reservationData.value.facility_id = computedFacility.value
        reservationData.value.reservation_date = computedDate.value
        currentDate.value = new Date()
        reservationData.value.reservation_type = props.types.at(0).value
        fieldList.value = fieldsOptions.value.map(e => ({
          desc: 0,
          title: e.name,
          subtitle: e.sport.name,
          value: e.id,
        }))
        if (computedDataParams.value.category) {
          reservationData.value.start_time = computedDataParams.value.start_time

          const finded = fieldsOptions.value.find(e => e.name === computedDataParams.value.category)
          if (finded) {
            fieldSelected.value = finded
            reservationData.value.field_id = finded.id
            fielsSelected.value = finded.id
            reservationData.value.players_qty = finded.players_qty
            includeAddOnsOption.value.subtitle = `$${finded.hourly_pricing}`

            calFullPrice()
            await filterDurations(finded.id)
          }
        } else {
          reservationData.value.start_time = computedMinutes.value.at(0).value
          const finded = fieldsOptions.value.at(0)
          fieldSelected.value = finded
          reservationData.value.field_id = finded.id
          fielsSelected.value = finded.id
          reservationData.value.players_qty = finded.players_qty
          includeAddOnsOption.value.subtitle = `$${finded.hourly_pricing}`

          calFullPrice()
          await filterDurations(finded.id)
        }
      } else {
        selectedPricingType.value = 0
        reservationData.value = {
          group_id: null,
          facility_id: null,
          field_id: null,
          circle_ids: null,
          user_console_id: null,
          reservation_type: null,
          reservation_date: null,
          start_time: null,
          start_date: null,
          end_date: null,
          reservation_time: 60,
          include_video: 'Y',
          price: null,
          players_qty: null,
          weekdays: [],
          repeat_n_weeks: 1,
          make_recurring: 'N',
          phone_numbers_list: [],
          contact_id: null,
          payment_type: 'F',
        }
        phoneInputs.value = [
          { value: null, code: null, phone: null },
        ]
        contactSelected.value = null
        contactSelectedId.value = null
        contacts.value = []
        searchQuery.value = ''
        playersGroupData.value = []
        minuteOptions.value = DURATION_OPTIONS
        fullPrice.value = null

        emit('update:editable', false)
        emit('initData')
      }
    })

    const cancelAction = () => {
      form.value.resetValidation()
      reservationData.value = Object.assign(initialData, {
        group_id: null,
        facility_id: null,
        field_id: null,
        circle_ids: null,
        user_console_id: null,
        reservation_type: null,
        reservation_date: null,
        start_time: null,
        reservation_time: 60,
        include_video: 'Y',
        price: null,
        players_qty: null,
        weekdays: [],
        repeat_n_weeks: 1,
        make_recurring: 'N',
        phone_numbers_list: [],
        contact_id: null,
        payment_type: 'F',
      })
      phoneInputs.value = [
        { value: null, code: null, phone: null },
      ]
      contactSelected.value = {
        first_name: null,
        last_name: null,
        phone_code: null,
        phone_prefix: null,
        phone_number: null,
        email: null,
        status: 'A',
        categories_ids: [],
      }
      playersGroupData.value = []
    }

    const saveAction = async checkConflicts => {
      if (form.value.validate()) {
        let resp = null
        let titleMessage = ''
        let message = ''
        let reservationBody = {}

        if (userData.value && userData.value.role !== 'A') reservationData.value.group_id = userData.value.group_id
        else reservationData.value.group_id = computedGroup.value

        if (!props.showAllowVideo) reservationData.value.include_video = 'N'

        if (reservationData.value.make_recurring === 'Y') {
          reservationBody = {
            group_id: reservationData.value.group_id,
            facility_id: computedFacility.value,
            field_id: reservationData.value.field_id,
            circle_ids: playersGroupData.value.map(e => e.id),
            user_console_id: userData.value.username,
            reservation_type: 'regular', // reservationData.value.reservation_type,
            reservation_time: reservationData.value.reservation_time,
            reservation_date: reservationData.value.start_date,
            start_time: getStartTime24(),
            include_video: reservationData.value.include_video,
            price: reservationData.value.price,
            players_qty: reservationData.value.players_qty,

            start_date: reservationData.value.start_date,
            end_date: reservationData.value.end_date,
            weekdays: reservationData.value.weekdays,
            repeat_n_weeks: reservationData.value.repeat_n_weeks,

            check_conflicts: checkConflicts || 'Y',

            phone_numbers_list: phoneInputs.value.filter(e => e.phone).map(e => e.phone),
            contact_id: reservationData.value.contact_id,
            payment_type: reservationData.value.payment_type,
          }

          resp = await createRecurringReservation(reservationBody)
        } else {
          reservationBody = {
            group_id: reservationData.value.group_id,
            facility_id: computedFacility.value,
            field_id: reservationData.value.field_id,
            circle_ids: playersGroupData.value.map(e => e.id),
            user_console_id: userData.value.username,
            reservation_type: reservationData.value.reservation_type,
            reservation_time: reservationData.value.reservation_time,
            reservation_date: reservationData.value.reservation_date,
            start_time: getStartTime24(),
            include_video: reservationData.value.include_video,
            price: reservationData.value.price,
            players_qty: reservationData.value.players_qty,

            phone_numbers_list: phoneInputs.value.filter(e => e.phone).map(e => e.phone),
            contact_id: reservationData.value.contact_id,
            payment_type: reservationData.value.payment_type,
          }

          resp = await createReservation(reservationBody)
        }

        if (resp.ok) {
          message = resp.message
          emit('refreshEvents')
          cancelAction()
          success(message)
        } else {
          titleMessage = tInContext('reservations.reservation_schedule_conflict', vm)
          if (Array.isArray(resp.data)) message = `${tInContext('reservations.days_with_conflict', vm)}: ${resp.data.join(', ')}`
          Swal.fire({
            title: titleMessage,
            text: `${message}`,
            icon: 'error',
            showCancelButton: true,
            showConfirmButton: Array.isArray(resp.data),
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: tInContext('reservations.create_reservations_without_conflict', vm),
            cancelButtonText: tInContext('tooltip.cancel', vm),
          }).then(async result => {
            if (result.isConfirmed) {
              await saveAction('N')
            }
          })
        }
      }
    }

    onBeforeMount(() => {
      Vue.directive('mask', VueMaskDirective)
    })

    onMounted(async () => {
      const countryCode = getCountryCode()
      if (countryCode) {
        const codeFinded = phoneCodeOptions.value.find(e => e.value === countryCode)
        if (codeFinded) initCode.value = countryCode
        else initCode.value = 'US'
      }
      phoneCode.value = initCode.value
      phoneInputs.value[0].code = initCode.value
    })

    return {
      fielsSelected,
      fieldList,
      includeAddOns,
      includeAddOnsContent,
      paymentTypeSelected,
      paymentTypeList,

      form,
      isDark,
      groupsOptions,
      facilitiesOptions,
      fieldsOptions,
      playersGroupsOptions,
      computedOnSitePaymentMethods,
      fullPrice,
      minuteWarnText,
      endDateWarnText,
      contactWarnText,
      minuteOptions,
      timeOptions,
      recurringFrequencyOptions,
      skillLevelOptions,
      reservationData,
      postData,
      userData,
      modalDate,
      modalStartTime,
      modalFrom,
      modalTo,
      dateFrom,
      dateTo,
      dateNow,
      maxDate,
      minDate,
      computedMinutes,
      weekdaysShort,
      fieldSelected,
      includeAddOnsOption,

      formDialog,
      isModalOpen,
      phoneInputs,
      computedPhoneNumbers,
      phoneCodeOptions,
      phoneCode,
      phoneNumber,

      formContactDialog,
      contacts,
      categories,
      autoContact,
      isContactModalOpen,
      contactSelectedId,
      contactSelected,
      contactData,
      computedContacts,
      searchQuery,
      searchLoading,

      showOnsitePayment,
      selectedOnsitePayment,
      isSearchGroupModalOpen,
      playersGroupData,
      computedPlayersGroup,

      // computed
      computedIsOpen,
      computedEditable,
      computedIsAdmin,
      computedDataParams,

      // i18n
      t,

      // validations
      required,
      emailValidator,
      integerValidator,
      regexValidator,
      charUnique,
      between,
      onlyNumberDot,
      onlyNumbers,
      validatePhone,
      validatePhones,
      isIncludeAddOns,
      selectedPricingType,

      // Methods
      avatarText,
      saveAction,
      cancelAction,
      onSelectType,
      onSelectDuration,
      calFullPrice,
      onSelectMakeRecurring,
      onSelectDate,
      onSelectField,
      onSelectAddOns,
      onSelectStartTime,
      allowedMinutes,
      allowedHours,
      addPhoneNumber,
      deletePhoneNumber,
      onCreateContact,
      addContact,
      onAddContact,
      sumMinutesOptions,
      formatCurrency,
      onSelectContact,
      onSelectPricingType,
      onIncludeAddOns,

      // icons
      icons: {
        mdiClockTimeFourOutline,
        mdiTimerOutline,
        mdiCalendarBlankOutline,
        mdiCalendar,
        mdiMenuDown,
        mdiClose,
        mdiPencilOutline,
        mdiMagnify,
        mdiEyeOutline,
        mdiInformationOutline,
        mdiAlert,
      },
    }
  },
}
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/styles.sass';

@include theme(v-subheader) using ($material) {
  white-space: nowrap;
  position: relative;
  height: 20px;

  .v-icon {
    color: map-deep-get($material, 'dividers');
  }
  .title-wrapper {
    &:not(.no-style) {
      width: 100%;
      position: absolute;
      left: 0;
      border-bottom: 1px solid map-deep-get($material, 'dividers');
      line-height: 0.1em;
    }
  }

  .title-wrapper span {
    background: map-deep-get($material, 'background');
    padding: 0 10px;

    font-size: 1rem !important;
    font-weight: 400;
    letter-spacing: 0.03125em !important;

    @include ltr() {
      margin-left: 16px;
    }
    @include rtl() {
      margin-right: 16px;
    }
  }
}

.theme--light.v-btn--active, .theme--dark.v-btn--active {
  background-color: #ff3232 !important;
}
</style>

<style lang="scss">
.custom-checkbox {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.8rem;
  border: 1px solid rgba(0, 0, 0, 0.14);

  .v-checkbox {
    margin-block-start: -0.375rem;
  }

  .cr-title {
    font-weight: 500;
  }
}
</style>
