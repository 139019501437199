<template>
  <div>
    <label class="font-weight-medium">{{ $t('reservations.participants') }}</label>

    <div class="d-flex mb-5"></div>

    <div class="d-flex mb-5">
      <v-btn
        outlined
        class="mr-2"
        :small="$vuetify.breakpoint.smAndDown"
      >
        <v-icon>{{ icons.mdiPlus }}</v-icon>
        Add participant
      </v-btn>
      <v-btn
        outlined
        class="mr-2"
        :small="$vuetify.breakpoint.smAndDown"
      >
        Mark all as Paid
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mdiPlus } from '@mdi/js'

export default {
  setup() {
    return {
      icons: {
        mdiPlus,
      },
    }
  },
}
</script>
