<template>
  <div class="h-full">
    <div class="drawer-header d-flex align-center justify-space-between mb-0">
      <div class="d-flex flex-column flex-sm-row justify-sm-space-between align-start align-sm-center w-100 ">
        <div class="d-flex align-center mb-2 mb-sm-0">
          <span class="font-weight-semibold text-base text--primary">{{ t('Booking') }} #{{ dataParams.reservation_id }}</span>
          <v-chip
            class="ml-2"
            small
            color="warning"
          >
            {{ dataParams.status_str }}
          </v-chip>
        </div>

        <div>
          <v-btn
            v-if="dataParams.recurring_id"
            class="mr-2"
            outlined
            :small="$vuetify.breakpoint.smAndDown"
            @click="$emit('recurring')"
          >
            <v-icon size="22">
              {{ icons.mdiAutorenew }}
            </v-icon>
            Recurring #{{ dataParams.recurring_id }}
          </v-btn>

          <v-btn
            class="mr-2 btn-share"
            outlined
            :small="$vuetify.breakpoint.smAndDown"
            @click="() => {
              $emit('share', dataParams)
              $emit('openClose', false)
            }"
          >
            <v-icon size="22">
              {{ icons.mdiShareVariant }}
            </v-icon>
          </v-btn>
        </div>
      </div>

      <v-btn
        icon
        small
        @click="$emit('openClose', false)"
      >
        <v-icon size="22">
          {{ icons.mdiClose }}
        </v-icon>
      </v-btn>
    </div>

    <div
      id="video-view"
      class="ma-4"
    >
      <v-row>
        <v-col
          cols="12"
        >
          <v-tabs
            v-model="currentTab"
            color="secondary"
            :show-arrows="true"
            style="box-shadow: none !important;"
          >
            <v-tab
              v-for="tab in computedTabs"
              :key="tab.title"
            >
              <v-icon
                v-if="tab.icon"
                size="20"
                class="me-2"
              >
                {{ tab.icon }}
              </v-icon>
              <span class="font-weight-bold">{{ $t(tab.title) }}</span>
            </v-tab>
          </v-tabs>

          <v-tabs-items
            id="video-tabs-content"
            v-model="tab"
            class="mt-5 overflow-visible"
          >
            <v-tab-item
              v-for="tab in computedTabs"
              :key="tab.component"
            >
              <component
                :is="tab.component"
                v-bind="tab.props"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>

      <div class="d-flex mb-5">
        <v-btn
          color="secondary"
          outlined
          class="mr-2"
          :small="$vuetify.breakpoint.smAndDown"
          @click="() => {
            onCancelReserve()
            $emit('openClose', false)
          }"
        >
          Cancel Booking
        </v-btn>
        <v-btn
          outlined
          class="mr-2"
          :small="$vuetify.breakpoint.smAndDown"
          @click="() => {
            $emit('share', dataParams)
            $emit('openClose', false)
          }"
        >
          Share
        </v-btn>

        <v-spacer />

        <v-btn
          outlined
          class="mr-2"
          :small="$vuetify.breakpoint.smAndDown"
          @click="$emit('openClose', false)"
        >
          Close
        </v-btn>
      </div>
      <!-- <v-divider class="ma-0" /> -->
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
import { ref, computed } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import {
  mdiClose,
  mdiShareVariant,
  mdiAutorenew,

  // mdiPlaylistCheck,
  // mdiLockClock,
} from '@mdi/js'
import usePermissions from '@core/utils/usePermissions'
import { error, success } from '@core/utils/toasted'
import Swal from 'sweetalert2'
import {
  cancelReservation,
  cancelRecurringReservation,
} from '@api'

import ReservationDetails from './ReservationDetails.vue'
import ReservationChat from './ReservationChat.vue'
import ReservationNotes from './ReservationNotes.vue'
import ReservationHistory from './ReservationHistory.vue'

// import RegularBookingForm from './RegularBookingForm.vue'
// import ListForSaleForm from './ListForSaleForm.vue'
// import BlockTimeForm from './BlockTimeForm.vue'

export default {
  components: {
    ReservationDetails,
    ReservationChat,
    ReservationNotes,
    ReservationHistory,
  },
  props: {
    dataParams: {
      type: [Object, null],
      required: true,
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const { hasPermission } = usePermissions()

    const userData = ref({})
    const tab = ref(0)
    const currentTab = ref(0)

    const tabs = ref([
      {
        icon: null, title: 'reservation_detail.edit_details', show: true, component: 'reservation-details', props: { dataParams: props.dataParams },
      },
      {
        icon: null, title: 'reservation_detail.chat', show: false, component: 'reservation-chat', props: { dataParams: props.dataParams },
      },
      {
        icon: null, title: 'reservation_detail.notes', show: false, component: 'reservation-notes', props: { dataParams: props.dataParams },
      },
      {
        icon: null, title: 'reservation_detail.history', show: false, component: 'reservation-history', props: { dataParams: props.dataParams },
      },
    ])
    const computedTabs = computed(() => tabs.value.filter(e => e.show))

    const computedHTML = computed(
      () => `
      <div style="text-align: start;">
        <input type="radio" name="cancel" value="1" id="radio1">
        <label for="radio1">
          No Show
        </label>
      </div>
      <div style="text-align: start;">
        <input type="radio" name="cancel" value="2" id="radio2">
        <label for="radio2">
          Not enough players
        </label>
      </div>
      <div style="text-align: start;">
        <input type="radio" name="cancel" value="3" id="radio3">
        <label for="radio3">
          Watching Important Soccer Match
        </label>
      </div>
      <div style="text-align: start;">
        <input type="radio" name="cancel" value="4" id="radio4">
        <label for="radio4">
          Holiday
        </label>
      </div>
      <div style="text-align: start;">
        <input type="radio" name="cancel" value="5" id="radio5">
        <label for="radio5">
          Bad Weather
        </label>
      </div>
      <div style="text-align: start;">
        <input type="radio" name="cancel" value="6" id="radio6">
        <label for="radio6">
          Other Reason
        </label>
      </div>
    `,
    )

    const onCancelReserve = async () => {
      Swal.fire({
        title: 'Cancel Booking',
        icon: 'warning',
        width: 700,
        html: computedHTML.value,
        showCancelButton: true,
        cancelButtonColor: '#d33',
        cancelButtonText: 'No',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Yes!',
        focusConfirm: false,
        preConfirm: async () => {
          const r1 = document.getElementById('radio1').checked ? 'Y' : 'N'
          const r2 = document.getElementById('radio2').checked ? 'Y' : 'N'
          const r3 = document.getElementById('radio3').checked ? 'Y' : 'N'
          const r4 = document.getElementById('radio4').checked ? 'Y' : 'N'
          const r5 = document.getElementById('radio5').checked ? 'Y' : 'N'
          const r6 = document.getElementById('radio6').checked ? 'Y' : 'N'

          const body = {
            r1,
            r2,
            r3,
            r4,
            r5,
            r6,
          }
          console.log('body', body)
        },
      }).then(async result => {
        let resp = null
        if (result.isConfirmed) {
          resp = await cancelReservation(props.dataParams.reservation_id)
        }

        if (result.isDenied) {
          resp = await cancelRecurringReservation(props.dataParams.recurring_id)
        }
        if (result.isConfirmed || result.isDenied) {
          if (resp.ok) {
            success(resp.message)
            emit('refreshEvents')
          } else {
            error(resp.message.text)
          }
        }
        emit('openClose', false)
      })
    }

    return {
      userData,
      tab,
      currentTab,
      tabs,
      computedTabs,

      onCancelReserve,
      hasPermission,
      t,

      icons: {
        mdiClose,
        mdiShareVariant,
        mdiAutorenew,
      },
    }
  },
}
</script>
<style lang="scss">
.v-btn--active::before {
  opacity: 0 !important;
}

.btn-share {
  min-width: 38px !important;
  padding: 0 !important;
}

@media (max-width: 599px) {
  .btn-share {
  min-width: 30px !important;
}
}
</style>
